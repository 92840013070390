import { axiosJSON, axiosMultipart } from './AxiosInterceptor';

// Get all organizations
const getOrganizations = async (query = '') => {
  try {
    const response = await axiosJSON.get('/api/organizations', {
      params: { query },
    });
    return response.data; // Assuming the API response includes a `data` field for organizations
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch organizations';
  }
};

// Add a new organization
const addOrganization = async (organizationData) => {
  try {
    const response = await axiosMultipart.post('/api/organizations', organizationData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to create organization';
  }
};
// Add the addReview method
const addReview = async (organizationId, reviewData) => {
  try {
    const response = await axiosJSON.post(`/api/organizations/${organizationId}/reviews`, reviewData);
    return response.data;
  } catch (error) {
    console.log("error", error);

    throw error || 'Failed to add review';
  }
};

// Add the addReview method
const addAdReview = async (reviewData) => {
  try {
    const response = await axiosJSON.post(`/api/reviews`, reviewData);
    return response.data;
  } catch (error) {
    console.log("error", error);

    throw error || 'Failed to add review';
  }
};

// Update an existing organization
const updateOrganization = async (id, organizationData) => {
  try {
    const response = await axiosMultipart.put(`/api/organizations/${id}`, organizationData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to update organization';
  }
};

// Delete an organization
const deleteOrganization = async (id) => {
  try {
    const response = await axiosJSON.delete(`/api/organizations/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete organization';
  }
};
// Get a single organization by ID
const getOrganizationById = async (id) => {
  try {
    const response = await axiosJSON.get(`/api/organizations/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch organization details';
  }
};

const getOrganizationReviews = async (orgId) => {
  try {
    const response = await axiosJSON.get(`api/reviews?organizationId=${orgId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch organization details';
  }
};
const getReviewsByUser = async (adId) => {
  try {
    const response = await axiosJSON.get(`api/reviews/by-user?adId=${adId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch user reviews';
  }
};


const OrganizationsService = {
  getOrganizations,
  addOrganization,
  updateOrganization,
  deleteOrganization,
  getOrganizationById,
  addReview,
  addAdReview,
  getOrganizationReviews,
  getReviewsByUser
};

export default OrganizationsService;
