import React from "react";
import { Card, Avatar, Rate, Typography, Divider } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

const { Text, Paragraph } = Typography;

const ReviewList = ({ reviews }) => {
  if (!reviews || reviews.length === 0) {
    return <Text className="no-reviews">No reviews available.</Text>;
  }

  return (
    <Card title="Reviews" bordered className="review-list-card">
      {reviews.map((review) => (
        <div key={review?._id || review?.createdAt} className="review-item">
          <Avatar
            src={`https://api.dicebear.com/7.x/initials/svg?seed=${review?.reviewer_name || "User"}`}
            size="large"
          />
          <div className="review-details">
            <Text strong>{review?.reviewer_name || "Anonymous"}</Text>
            <Rate disabled defaultValue={review?.overall_rating || 0} />
            <Paragraph>{review?.comment || "No comment provided."}</Paragraph>
            <Text type="secondary">
              <ClockCircleOutlined />{" "}
              {new Date(review?.createdAt).toLocaleDateString()}
            </Text>
          </div>
          <Divider className="review-divider" />
        </div>
      ))}
    </Card>
  );
};

export default ReviewList;
