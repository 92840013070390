import { axiosJSON, axiosMultipart } from './AxiosInterceptor';

/**
 * Fetch all cities with pagination
 * @param {number} page - Page number (default: 1)
 * @param {number} limit - Number of cities per page (default: 15)
 * @returns {Promise<Object>} - Paginated list of cities
 */
const getCities = async (page = 1, limit = 15) => {
  try {
    const response = await axiosJSON.get('/api/cities', { params: { page, limit } });
    return response.data; // Return paginated data
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch cities';
  }
};

/**
 * Search all cities
 * @param {string} query - Search query (optional)
 * @returns {Promise<Object>} - Search results for cities
 */
const searchAllCities = async (query = '') => {
  try {
    const response = await axiosJSON.get('/api/cities/search', { params: { query } });
    return response.data; // Return search results
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch cities';
  }
};

/**
 * Create a new city
 * @param {Object} cityData - City details to be added
 * @returns {Promise<Object>} - Created city data
 */
const addCity = async (cityData) => {
  try {
    const response = await axiosMultipart.post('/api/cities', cityData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to create city';
  }
};

/**
 * Update an existing city
 * @param {string} id - City ID
 * @param {Object} cityData - Updated city details
 * @returns {Promise<Object>} - Updated city data
 */
const updateCity = async (id, cityData) => {
  try {
    const response = await axiosJSON.put(`/api/cities/${id}`, cityData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to update city';
  }
};

/**
 * Delete a city
 * @param {string} id - City ID
 * @returns {Promise<Object>} - API response
 */
const deleteCity = async (id) => {
  try {
    const response = await axiosJSON.delete(`/api/cities/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete city';
  }
};

/**
 * Cities service object encapsulating all API calls
 */
const CitiesService = {
  getCities,
  searchAllCities,
  addCity,
  updateCity,
  deleteCity,
};

export default CitiesService;
