import { axiosJSON, axiosMultipart } from './AxiosInterceptor';

/**
 * User Registration
 * @param {Object} body - User registration data
 * @returns {Promise<Object>} - Response from the API
 */
const register = async (body) => {
  try {
    const response = await axiosJSON.post('/api/users/register', body);
    return response;
  } catch (error) {
    throw error.response?.data?.message || 'Registration failed';
  }
};

/**
 * User Login
 * @param {string} email - User email
 * @param {string} password - User password
 * @returns {Promise<Object>} - Login response with token
 */
const login = async (email, password) => {
  try {
    const response = await axiosJSON.post('/api/users/login', { email, password });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Login failed';
  }
};

/**
 * Google Login
 * @param {Object} userData - Google user data
 * @returns {Promise<Object>} - Response from the API
 */
const googleLogin = async (userData) => {
  try {
    const response = await axiosJSON.post('/api/users/google-login', userData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Google login failed';
  }
};

/**
 * Apple Login
 * @param {Object} appleUser - Apple user data
 * @returns {Promise<Object>} - Response from the API
 */
const appleLogin = async (appleUser) => {
  try {
    const response = await axiosJSON.post('/api/users/apple-login', appleUser);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Apple login failed';
  }
};

/**
 * Request Password Reset
 * @param {string} email - User email
 * @returns {Promise<Object>} - Response from the API
 */
const request_reset_password = async (email) => {
  try {
    const response = await axiosJSON.post('/api/users/request_reset_password', { email });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Reset Password Request failed';
  }
};

/**
 * Reset Password
 * @param {string} token - Reset token
 * @param {string} new_password - New password
 * @returns {Promise<Object>} - Response from the API
 */
const resetPassword = async (token, new_password) => {
  try {
    const response = await axiosJSON.post('/api/users/reset_password', { new_password }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Forget Reset Password failed';
  }
};

/**
 * Change Password
 * @param {string} token - Authorization token
 * @param {string} current_password - Current password
 * @param {string} new_password - New password
 * @returns {Promise<Object>} - Response from the API
 */
const changePassword = async (token, current_password, new_password) => {
  try {
    const response = await axiosJSON.post('/api/users/change_password', { current_password, new_password }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Change Password failed';
  }
};

/**
 * Verify Code
 * @param {string} user - User ID
 * @param {string} verification_code - Verification code
 * @returns {Promise<Object>} - Response from the API
 */
const verifyCode = async (user, verification_code) => {
  try {
    const response = await axiosJSON.post('/api/verify/email_code', { user, verification_code });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Verification failed';
  }
};

/**
 * Resend Verification Code
 * @param {string} email - User email
 * @returns {Promise<Object>} - Response from the API
 */
const resendCode = async (email) => {
  try {
    const response = await axiosJSON.post('/api/verify/resend_email', { email });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Resend failed';
  }
};

/**
 * Get All Users
 * @returns {Promise<Object[]>} - List of users
 */
const getUsers = async () => {
  try {
    const response = await axiosJSON.get('/api/users');
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch users';
  }
};

/**
 * Delete User
 * @param {string} id - User ID
 * @returns {Promise<Object>} - Response from the API
 */
const deleteUser = async (id) => {
  try {
    const response = await axiosJSON.delete(`/api/users/delete_user/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete user';
  }
};

/**
 * Get User by Email
 * @param {string} email - User email
 * @returns {Promise<Object>} - User data
 */
const getUserByEmail = async (email) => {
  try {
    const response = await axiosJSON.get(`/api/users/${email}`);
    return response.data.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch user';
  }
};

/**
 * Update User
 * @param {string} email - User email
 * @param {Object|FormData} data - Updated user data
 * @returns {Promise<Object>} - Response from the API
 */
const updateUser = async (email, data) => {
  try {
    const response = await axiosMultipart.put(`/api/users/update/${email}`, data);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to update user';
  }
};

/**
 * Add User
 * @param {Object} data - User data
 * @returns {Promise<Object>} - Response from the API
 */
const addUser = async (data) => {
  try {
    const response = await axiosJSON.post('/api/users/add_user', data);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to add user';
  }
};

/**
 * Update User Appearance Preference
 * @param {string} email - User email
 * @param {Object} data - Appearance preference data
 * @returns {Promise<Object>} - Response from the API
 */
const updateAppearancePreference = async (email, data) => {
  try {
    const response = await axiosJSON.put(`/api/users/appearance_preference/${email}`, data);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to update appearance preference';
  }
};

const AuthService = {
  login,
  register,
  googleLogin,
  appleLogin,
  request_reset_password,
  resetPassword,
  changePassword,
  verifyCode,
  resendCode,
  getUsers,
  deleteUser,
  getUserByEmail,
  updateUser,
  addUser,
  updateAppearancePreference,
};

export default AuthService;
