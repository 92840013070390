import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, message } from "antd";
import List from "../../components/List/List";
import AdsService from "../../services/AdService";
import "./Adventure.css";
import LocalStorageService from "../../services/LocalStorage";
import Loader from "../../components/Loader";
import { FilterContext } from "../../contexts/FilterContext";

const { Title, Text } = Typography;

const Adventure = () => {
  const navigate = useNavigate();
  const token = LocalStorageService.getItem("token");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cityId = queryParams.get("search");
  const { filters } = useContext(FilterContext); // Get filters from context
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const observerRef = useRef(null);
  const loadingRef = useRef(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(4);
  const [total, setTotal] = useState(0);

  const loadMore = useCallback(() => {
    if (paginationLoading || items.length >= total) return;
    fetchAds(cityId, filters, page + 1, limit, true);
    setPage(prev => prev + 1);
  }, [cityId, filters, page, limit, items.length, total, paginationLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    observerRef.current = observer;

    return () => observerRef.current?.disconnect();
  }, [loadMore]);

  useEffect(() => {
    setItems([]);
    setPage(1);
    fetchAds(cityId, filters, 1, limit, false);
  }, [cityId, filters, limit]);

  const fetchAds = async (cityId, filters = {}, page, limit, isPagination = false) => {
    isPagination ? setPaginationLoading(true) : setLoading(true);
    try {
      const data = await AdsService.getAdsByCity(cityId, filters, page, limit);
      const ads = data.data?.map(ad => ({
        ...ad,
        isFavorited: ad.favorite,
      })) || [];

      setItems(prev => page === 1 ? ads : [...prev, ...ads]);
      setTotal(data.total || 0);
    } catch (error) {
      message.error("Failed to fetch ads");
      console.error("API Error:", error);
    } finally {
      isPagination ? setPaginationLoading(false) : setLoading(false);
    }
  };

  const toggleFavorite = async (adId) => {
    try {
      if (!token) {
        navigate("/login");
        return;
      }
      // Optimistically toggle favorite state
      const updatedItems = items.map((item) => {
        if (item._id === adId) {
          const newFavoriteState = !item.isFavorited;
          item.isFavorited = newFavoriteState; // Update favorite state
          // Show a message based on the new favorite state
          message.success(
            newFavoriteState ? "Added to favorites" : "Removed from favorites"
          );
        }
        return item;
      });

      setItems([...updatedItems]); // Update UI with optimistic state

      // Persist favorite state change in backend
      await AdsService.toggleFavorite(adId);
    } catch (error) {
      message.error("Failed to update favorite status. Please try again.");
      console.error("Error updating favorite status:", error);
    }
  };


  return (

    <div className="container">
      <div className="header">
        <Title className="title" level={2}>
          Adventures
        </Title>
        <Text type="secondary" className="subTitle">
          Explore the best activities for thrill-seekers!
        </Text>
      </div>

      {loading ? (<div className="adventureloading-container">
        <Loader size={2250} />
      </div>) : (<div className="listContainer">
        {items.length === 0 ? (
          <div className="no-data-container">
            <Text>No adventures found for the selected filters.</Text>
          </div>
        ) : (
          <List items={items} onToggleFavorite={toggleFavorite} />
        )}
      </div>)}

      <div ref={loadingRef} style={{ height: 50, background: "transparent" }}>
      </div>
    </div>
  );
};

export default Adventure;
