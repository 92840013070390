import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Filters from "../../components/Filters";
import "./AdventurePage.css";
import { message } from "antd";
import { FilterContext } from "../../contexts/FilterContext";

const AdventurePage = () => {
  const { filters, updateFilters } = useContext(FilterContext); // Use FilterContext

  const handleSearchChange = (value) => {
    updateFilters({ searchKeyword: value }); // Update only the searchKeyword
  };

  const handleCityChange = (value) => {
    updateFilters({ selectedCity: value }); // Update only the selectedCity
  };

  const handleFilterChange = (key, value) => {
    updateFilters({ [key]: value }); // Update other filters like priceRange and duration
  };

  const resetFilters = () => {
    updateFilters({
      searchKeyword: "",
      priceRange: "",
      duration: "",
      selectedCity: null,
      minPrice: null, // Reset minimum price
      maxPrice: null, // Reset maximum price
      start_date: null, // Reset start date
      end_date: null, // Reset end date
    });
  };



  const applyFilters = () => {
    message.success("Filters applied successfully!");
  };

  return (
    <div className="adventure-page">
      <Filters
        searchKeyword={filters.searchKeyword}
        selectedCity={filters.selectedCity}
        filterOptions={{
          minPrice: filters.minPrice,
          maxPrice: filters.maxPrice,
          start_date: filters.start_date,
          end_date: filters.end_date,
        }}
        handleSearchChange={handleSearchChange}
        handleCityChange={handleCityChange}
        handleFilterChange={handleFilterChange}
        applyFilters={applyFilters}
        resetFilters={resetFilters}
      />

      <Outlet />
    </div>
  );
};

export default AdventurePage;
