import React, { useEffect, useState } from 'react';
import { Form, Input, Button, DatePicker, InputNumber, message } from 'antd';
import dayjs from 'dayjs';
import PhoneNumberInput from '../../components/PhoneInput';

const { RangePicker } = DatePicker;

const BookingForm = ({ initialValues, onFinish, loading }) => {
  const [form] = Form.useForm();
  const [ads, setAdDetails] = useState(null);


  useEffect(() => {
    setAdDetails(Array.isArray(initialValues?.ad) ? initialValues?.ad : [initialValues?.ad]);

    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        booking_dates: initialValues.booking_dates
          ? initialValues.booking_dates.map((date) => (date ? dayjs(date) : null))
          : null, // Parse booking dates using dayjs
        ad: initialValues?.ad?._id,
        organization: initialValues.organization?._id,
      });
    }
  }, [initialValues, form]);

  const handleFinish = (values) => {
    const { booking_dates, ...rest } = values;
    const [start_date, end_date] = booking_dates || [];
    onFinish({ ...rest, start_date: start_date?.toISOString(), end_date: end_date?.toISOString() });
  };
  const handleValuesChange = (changedValues, allValues) => {
    const { participants, ad } = allValues;
  
    // Ensure `ads` is defined and is an array
    if (Array.isArray(ads)) {
      const selectedAd = ads.find((adItem) => adItem._id === ad);
  
      if (participants && selectedAd) {
        const basePrice = selectedAd.price;
        const totalPrice = participants * basePrice;
        form.setFieldsValue({ total_price: totalPrice });
      }
    } else {
      console.error("`ads` is not an array or is undefined.");
    }
  };
  
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      onFinishFailed={() => message.error('Please correct the errors before submitting')}
      onValuesChange={handleValuesChange}
    >
      <Form.Item
        label="Ad"
        name="ad"
        hidden
        rules={[{ required: true, message: 'Please select an Ad' }]}
      >
        <Input placeholder="Enter Ad ID" />
      </Form.Item>
      <Form.Item
        label="Full Name"
        name="full_name"
        rules={[
          { required: true, message: "Please enter Full Name" },
        ]}
      >
        <Input placeholder="Enter Full Name" />
      </Form.Item>
      <Form.Item
        label="User"
        name="user"
        rules={[{ required: true, message: 'Please enter the User ID' }]}
      >
        <Input placeholder="Enter User ID" disabled />
      </Form.Item>
      <Form.Item
        label="Organization"
        name="organization"
        hidden
        rules={[{ required: true, message: 'Please enter the Organization ID' }]}
      >
        <Input placeholder="Enter Organization ID" />
      </Form.Item>
      <Form.Item
        label="Booking Dates"
        name="booking_dates"
        hidden
        rules={[{ required: true, message: 'Please select booking dates' }]}
      >
        <RangePicker />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="contact_no"
        rules={[{ required: true, message: "Phone number is required." }]}
      >
        <PhoneNumberInput
          value={form.getFieldValue("contact_no") || ""}
          onChange={(phone) => form.setFieldsValue({ contact_no: phone })}
        />
      </Form.Item>
      <Form.Item
        label="Number of Participants"
        name="participants"
        rules={[{ required: true, message: 'Please enter the number of participants' }]}
      >
        <InputNumber min={1} placeholder="Enter number of participants" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label="Total Price"
        name="total_price"
        rules={[{ required: true, message: 'Please enter the total price' }]}
      >
        <InputNumber
          min={0}
          placeholder="Enter total price"
          style={{ width: '100%' }}
          prefix="PKR"
          disabled
        />
      </Form.Item>
      <Form.Item
        label="Special Requests"
        name="special_requests"
      >
        <Input.TextArea placeholder="Enter any special requests" rows={4} />
      </Form.Item>
      <Form.Item style={{ float: 'right' }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save Booking
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BookingForm;
