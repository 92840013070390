import React, { useEffect, useState, useCallback } from "react";
import { Input, Select, Button, message, DatePicker, InputNumber } from "antd";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import "./Filters.css";
import CitiesService from "../services/CitiesService";

const { RangePicker } = DatePicker;

const Filters = ({
  searchKeyword,
  selectedCity,
  filterOptions,
  handleSearchChange,
  handleCityChange,
  handleFilterChange,
  applyFilters,
  resetFilters,
}) => {
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cityIdFromUrl = queryParams.get("search");

  const fetchCities = useCallback(async () => {
    try {
      const response = await CitiesService.searchAllCities();
      const citiesData = response.data || [];
      setCities(citiesData);

      if (cityIdFromUrl && !selectedCity) {
        const cityFromUrl = citiesData.find((city) => city._id === cityIdFromUrl);
        if (cityFromUrl) {
          handleCityChange(cityFromUrl._id);
        }
      }
    } catch (error) {
      message.error(error || "Failed to load cities");
      setCities([]);
    }
  }, [cityIdFromUrl, selectedCity, handleCityChange]);

  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  const onCityChange = (value) => {
    handleCityChange(value);
    const updatedParams = new URLSearchParams(search);
    if (value) {
      updatedParams.set("search", value);
    } else {
      updatedParams.delete("search");
    }
    navigate(`/adventures?${updatedParams.toString()}`);
  };

  // Handle date range change
  const onDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      handleFilterChange("start_date", dayjs(dates[0]).format("YYYY-MM-DD"));
      handleFilterChange("end_date", dayjs(dates[1]).format("YYYY-MM-DD"));
    } else {
      handleFilterChange("start_date", null);
      handleFilterChange("end_date", null);
    }
  };

  return (
    <div className="filters">
      <Select
        showSearch
        optionFilterProp="children"
        allowClear
        placeholder="City"
        onChange={onCityChange}
        value={selectedCity}
        style={{ width: "150px" }}
      >
        {cities?.map((city) => (
          <Select.Option key={city._id} value={city._id}>
            {city.name}
          </Select.Option>
        ))}
      </Select>
      <Input
        placeholder="Search adventures..."
        value={searchKeyword}
        onChange={(e) => handleSearchChange(e.target.value)}
        style={{ width: "200px" }}
        allowClear
      />
      <InputNumber
        placeholder="Min Price"
        min={0} // Restrict to non-negative values
        onChange={(value) => handleFilterChange("minPrice", value)}
        value={filterOptions.minPrice || undefined} // Ensure controlled component resets correctly
        style={{ width: "100px" }}
      />
      <InputNumber
        placeholder="Max Price"
        min={0} // Restrict to non-negative values
        onChange={(value) => handleFilterChange("maxPrice", value)}
        value={filterOptions.maxPrice || undefined} // Ensure controlled component resets correctly
        style={{ width: "100px" }}
      />

      <RangePicker
        onChange={onDateRangeChange}
        value={
          filterOptions.start_date && filterOptions.end_date
            ? [dayjs(filterOptions.start_date), dayjs(filterOptions.end_date)]
            : null
        } // Use null to reset the RangePicker
        style={{ width: "300px" }}
      />

      <Button
        type="default"
        onClick={resetFilters}
        style={{ background: "var(--text-color)", color: "var(--input-bg)" }}
      >
        Reset Filters
      </Button>
    </div>
  );
};

export default Filters;
