import React, { useState } from 'react';
import { message } from 'antd';
import ContactForm from './Form';
import ContactService from "../../services/ContactService";
import "../contact-us/ContactUs.css";
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      // Call the service with the form data
      await ContactService.sendMessage(values);
      message.success('Message sent successfully. You will be contacted within 4 working days.');
      navigate("/login")

    } catch (error) {
      message.error('Failed to send message');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <p className="contact-text">
        Have questions or concerns?<br />
        Feel free to send us a message below.
      </p>
      <ContactForm onFinish={handleFinish} loading={loading} />
    </div>
  );
};

export default ContactUs;
