import React from 'react';
import { Form, Input, Button, message } from 'antd';
import PhoneNumberInput from '../../components/PhoneInput';

const ContactForm = ({ onFinish, loading }) => {
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    await onFinish(values); // Call the parent `onFinish`
    form.resetFields(); // Reset the form after submission
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      onFinishFailed={() => message.error('Please fix the errors before submitting')}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter your name' }]}
      >
        <Input placeholder="Enter your name" />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'Please enter a valid email address' },
        ]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>
      <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            { required: true, message: 'Please enter your phone' },
          ]}
        >
          <PhoneNumberInput
            value={form.getFieldValue('phone') || ''}
            onChange={(phone) => form.setFieldsValue({ phone })}
          />
        </Form.Item>
      <Form.Item
        label="Message"
        name="message"
        rules={[{ required: true, message: 'Please enter your message' }]}
      >
        <Input.TextArea rows={4} placeholder="Write your message here" />
      </Form.Item>
      <Form.Item style={{ float: 'right' }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Send Message
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContactForm;
