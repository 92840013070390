import React, { useEffect, useState } from "react";
import { Card, Row, Col, Typography, Statistic, message, Modal, Button } from "antd";
import {
  EnvironmentOutlined,
  // MailOutlined,
  // PhoneOutlined,
  GlobalOutlined,
  CalendarOutlined,
  StarFilled,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import AdsService from "../../services/AdService";
import OrganizationService from "../../services/OrganizationService";
import "./Organization.css"; // Import the updated CSS file
import Loader from "../../components/Loader";
import ReviewList from "./ReviewList";
import List from "../../components/List/List";
import ChatBox from "../../components/Chat/ChatBox";


const { Title, Paragraph, Text } = Typography;

const OrganizationPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("search");

  const [organization, setOrganization] = useState(null);
  const [averageRating, setAverageRating] = useState(0);
  const [reviews, setReviews] = useState([]); // Separate state for reviews
  const [adventures, setAdventures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isChatModalVisible, setIsChatModalVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null); // Organization owner ID

  const handleOpenChat = (userId) => {
    setSelectedUserId(userId);
    setIsChatModalVisible(true);
  };

  const handleCloseChat = () => {
    setIsChatModalVisible(false);
    setSelectedUserId(null);
  };


  useEffect(() => {
    fetchOrganizationDetails(id);
    fetchOrganizationAds(id);
  }, [id]);

  // Fetch Organization Details
  const fetchOrganizationDetails = async (orgId) => {
    try {
      const { data } = await OrganizationService.getOrganizationById(orgId);
      setOrganization(data);
  
      // Fetch reviews for the organization
      const reviewResponse = await OrganizationService.getOrganizationReviews(orgId);
      const reviewsData = reviewResponse.data || [];
      setReviews(reviewsData);
  
      // Calculate and set average rating
      const validReviews = reviewsData.filter((review) => review?.overall_rating);
      const calculatedAverageRating =
        validReviews.reduce((acc, review) => acc + review.overall_rating, 0) /
        (validReviews.length || 1);
  
      setAverageRating(calculatedAverageRating.toFixed(1)); // Update state with average rating
    } catch (error) {
      // Handle specific "already reviewed" error
      if (error.response?.data?.message === "You have already reviewed this ad.") {
        message.warning("You have already submitted a review for this ad.");
      } else {
        message.error("Failed to fetch organization details.");
        console.error("Organization Fetch Error:", error);
      }
    }
  };
  
  // Fetch Ads for Organization
  const fetchOrganizationAds = async (orgId) => {
    setLoading(true);
    try {
      const { data } = await AdsService.getAdsByOrganization(orgId);
      setAdventures(data);
    } catch (error) {
      message.error("Failed to fetch adventures.");
      console.error("Adventures Fetch Error:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!organization && loading) {
    return (
      <div className="loading-container">
        <Loader size={2250} />
      </div>
    );
  }

  if (!organization) {
    return (
      <div className="organization-spinner">
        <Text type="danger">Organization details not found.</Text>
      </div>
    );
  }

  return (
    <div className="organization-container">
      {/* Header Section */}
      <div className="organization-header-card">
        <div
          className="organization-header-bg"
          style={{
            backgroundImage: `url("/images/44.jpg")`,
          }}
        >
          <div className="organization-header-overlay">
            <Title level={1} className="organization-header-title">
              {organization.name}
            </Title>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <Row gutter={[24, 24]} className="organization-stats-row">
        <Col xs={24} sm={8}>
          <Statistic
            title="Average Rating"
            value={averageRating} // Display average rating here
            prefix={<StarFilled style={{ color: "#fadb14" }} />}
            suffix="/ 5"
            className="organization-statistic"
          />
        </Col>
        <Col xs={24} sm={8}>
          <Statistic
            title="Total Reviews"
            value={reviews.length}
            className="organization-statistic"
          />
        </Col>
        <Col xs={24} sm={8}>
          <Statistic
            title="Member Since"
            value={new Date(organization.created_at).getFullYear()}
            prefix={<CalendarOutlined />}
            className="organization-statistic"
          />
        </Col>
      </Row>

      {/* Contact Info and Reviews */}
      <Row gutter={[24, 24]}>
        {/* Contact Info */}
        <Col xs={24} lg={8}>
          <Card
            title="Contact Information"
            bordered
            className="organization-contact-card"
          >
            <Paragraph className="organization-contact-info">
              <EnvironmentOutlined /> <Text strong>Address:</Text>{" "}
              {organization.address || "Not specified"}
            </Paragraph>
            {/* <Paragraph className="organization-contact-info">
              <MailOutlined /> <Text strong>Email:</Text>{" "}
              <a href={`mailto:${organization.contact_email}`}>
                {organization.contact_email}
              </a>
            </Paragraph>
            <Paragraph className="organization-contact-info">
              <PhoneOutlined /> <Text strong>Phone:</Text>{" "}
              <a href={`tel:${organization.contact_phone}`}>
                {organization.contact_phone}
              </a>
            </Paragraph> */}
            <Paragraph className="organization-contact-info">
              <GlobalOutlined /> <Text strong>Website:</Text>{" "}
              {organization.website ? (
                <a
                  href={organization.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {organization.website}
                </a>
              ) : (
                "Not specified"
              )}
            </Paragraph>
          </Card>
        </Col>

        {/* Reviews */}
        <Col xs={24} lg={16}>
          <ReviewList reviews={reviews} />
        </Col>
      </Row>

      {/* Adventures List */}
      <div className="organization-adventures-section">
        <Title level={2} className="organization-title">
          Upcoming Adventures
        </Title>
        {loading ? (
          <div className="loading-container">
            <Loader size={2250} />
          </div>
        ) : adventures?.length ? (
          <List
            items={adventures}
            renderItem={(ad) => (
              <Card
                hoverable
                cover={
                  <img
                    alt={ad.title}
                    src={ad.images[0]}
                    className="organization-adventure-cover"
                  />
                }
                className="organization-adventure-card"
              >
                <Card.Meta
                  title={ad.title}
                  description={`${ad.description.substring(0, 100)}...`}
                />
                <Paragraph style={{ marginTop: "1rem" }}>
                  <Text strong>Price: </Text> PKR{" "}
                  {ad.price.toLocaleString()} <br />
                  <Text strong>Duration: </Text> {ad.duration}
                </Paragraph>
              </Card>
            )}
          />
        ) : (
          <Text className="organization-no-data">
            No adventures available for this organization.
          </Text>
        )}
      </div>
      {/* Message Organization Button */}
      <Button
        type="primary"
        className="message-organization-btn"
        onClick={() => handleOpenChat(organization.user_id)} // Replace `organization.ownerId` with the actual organization owner's ID
      >
        Message Organization
      </Button>

      {/* Chat Modal */}
      <Modal
        title={`Chat with ${organization.name}`}
        visible={isChatModalVisible}
        onCancel={handleCloseChat}
        footer={null}
        width={800}
      >
        <ChatBox selectedUserId={selectedUserId} />
      </Modal>
    </div>
  );
};

export default OrganizationPage;
