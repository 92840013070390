import { Outlet, Navigate } from "react-router-dom";
import LocalStorageService from "../services/LocalStorage";

const getUserRole = () => {
  const user = JSON.parse(LocalStorageService?.getItem("user"));
  if (!user || !user.data?.role) {
    console.warn("User role not found in local storage.");
  }
  return user?.data?.role || null; // Default to null if no role is found
};

const ProtectedRoutes = ({ allowedRoles = [], fallback = null }) => {
  const token = LocalStorageService.getItem("token");
  const userRole = getUserRole();

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
    console.warn(`User role '${userRole}' is not authorized to access this route.`);
    return fallback || <Navigate to="/not-authorized" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
