import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// import { useEffect } from 'react';
import Home from './pages/Home';
import Verification from './pages/auth/Verification/Verification';
import ProtectedRoutes from './utils/ProtectedRoutes';
import Layout from './components/Layout';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/auth/PasswordResetRequest';
import Login from './pages/auth/Login/Login';
import Register from './pages/auth/Register/Register';
import AuthLayout from './pages/auth/Layout';
import Adventure from './pages/adventure/Adventure';
import MyAccount from './pages/my-account/MyAccount';
import General from './pages/my-account/general/General';
import Security from './pages/my-account/security/Security';
import Detail from './pages/adventure/detail/Detail';
import Booking from './pages/booking/Booking';
import ContactUs from './pages/contact-us/ContactUs';
import About from './pages/about/About';
import Favorites from './pages/favorites/Favorites';
import MyBookings from './pages/my-bookings/MyBookings';
import OrganizationPage from './pages/organization/Organization';
import AdventurePage from './pages/adventure/AdventurePage';
import { FilterProvider } from './contexts/FilterContext';
import ForgetPassword from './pages/auth/ForgetPassword';
import Inbox from './pages/inbox/Inbox';
// import notificationService from "./services/NotificationService";


function App() {

  // useEffect(() => {
  //   // Request permission and get token when app loads
  //   notificationService.init();
  // }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route element={<Layout />}>
          <Route exact path="/" element={<Home />} />
          {/* Adventures Section wrapped in FilterProvider */}
          <Route
            path="/adventures"
            element={
              <FilterProvider>
                <AdventurePage />
              </FilterProvider>
            }
          >
            <Route index element={<Adventure />} /> 
          </Route>
          <Route path="/adventures/detail" element={<Detail />} />
          <Route path="/organization" element={<OrganizationPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about" element={<About />} />

        </Route>

        {/* Auth Routes */}
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forget-password" element={<ForgetPassword />} />

        </Route>

        {/* Protected Routes */}
        <Route
          element={
            <Layout>
              <ProtectedRoutes />
            </Layout>
          }
        >
          <Route path="/booking" element={<Booking />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/my-bookings" element={<MyBookings />} />
          <Route path="/inbox" element={<Inbox />} />


          {/* Add more protected routes as needed */}
        </Route>


        {/* Organization/User Protected Routes */}
        <Route element={<ProtectedRoutes allowedRoles={['ORGANIZATION', 'USER', 'ADMIN']} />}>
          <Route path="/my-account/*" element={<MyAccount />}>
            <Route index element={<Navigate to="/my-account/general" replace />} />
            <Route path="general" element={<General />} />
            <Route path="security" element={<Security />} />
          </Route>
        </Route>
 
        {/* 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
