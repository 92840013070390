import { axiosJSON } from './AxiosInterceptor';

// Get all contacts with optional query parameters
const getContacts = async (params = {}) => {
  try {
    const response = await axiosJSON.get('/api/contact-us', { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching contacts:", error.response?.data || error.message);
    throw error; // Let the caller handle the error
  }
};

// Send a Contact Us message
const sendMessage = async (contactData) => {
  try {
    const response = await axiosJSON.post('/api/contact-us', contactData);
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error.response?.data || error.message);
    throw error; // Let the caller handle the error
  }
};

// Update a Contact Us entry
const updateContact = async (id, updateData) => {
  try {
    const response = await axiosJSON.put(`/api/contact-us/${id}`, updateData);
    return response.data;
  } catch (error) {
    console.error("Error updating contact:", error.response?.data || error.message);
    throw error; // Let the caller handle the error
  }
};

// Delete a Contact Us entry
const deleteContact = async (id) => {
  try {
    const response = await axiosJSON.delete(`/api/contact-us/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting contact:", error.response?.data || error.message);
    throw error; // Let the caller handle the error
  }
};

// Service object for better encapsulation and reusability
const ContactService = {
  getContacts,
  sendMessage,
  updateContact,
  deleteContact,
};

export default ContactService;
