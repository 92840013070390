// components/ReviewForm.js
import React from "react";
import { Form, Input, Button, Rate, message } from "antd";

const ReviewForm = ({ onSubmit, canSubmitReview }) => {
  const [form] = Form.useForm();
  // Handle form submission
  const handleFinish = async (values) => {
    const transformed = {
        comment: values.comment,
        ratings: {
            value_for_money: values.vom,
            accommodation: values.accommodation,
            guides: values.guides,
            transportation: values.transportation,
            activities: values.activities,
        },
    };

    if (!canSubmitReview) {
        message.warning("You cannot add a review without a valid booking.");
        return;
    }

    form.resetFields();
    onSubmit(transformed);
};


  return (
    <div>
      {canSubmitReview ? (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          className="organization-add-review-form"
        >
          <Form.Item
            label="Value for Money"
            name="vom"
            rules={[{ required: true, message: "Please provide a rating" }]}
          >
            <Rate />
          </Form.Item>
          <Form.Item
            label="Accommodation"
            name="accommodation"
            rules={[{ required: true, message: "Please provide a rating" }]}
          >
            <Rate />
          </Form.Item>
          <Form.Item
            label="Transportation"
            name="transportation"
            rules={[{ required: true, message: "Please provide a rating" }]}
          >
            <Rate />
          </Form.Item>
          <Form.Item
            label="Activities"
            name="activities"
            rules={[{ required: true, message: "Please provide a rating" }]}
          >
            <Rate />
          </Form.Item>

          <Form.Item
            label="Guides"
            name="guides"
            rules={[{ required: true, message: "Please provide a rating" }]}
          >
            <Rate />
          </Form.Item>

          <Form.Item

            label="Comment"
            name="comment"
            rules={[{ required: true, message: "Please add a comment" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit Review
          </Button>
        </Form>
      ) : (
        <p>You can only leave a review if you have booked a tour with this organization.</p>
      )}
    </div>
  );
};

export default ReviewForm;
