import React, { useState } from "react";
import { List, Avatar, Typography, Input } from "antd";

const { Text } = Typography;

const ThreadList = ({ threads, onSelectThread, selectedUserId }) => {
  const [searchValue, setSearchValue] = useState(""); // State for search input

  // Filter threads based on the search input
  const filteredThreads = threads.filter((thread) => {
    const fullName = `${thread.user.first_name || ""} ${thread.user.last_name || ""}`.toLowerCase();
    return fullName.includes(searchValue.toLowerCase());
  });

  return (
    <div
      style={{
        // width: "300px",
        borderRight: "1px solid var(--border)",
        backgroundColor: "var(--main-container-bg)",
        overflowY: "auto",
      }}
    >
      {/* Search Bar */}
      <div style={{ padding: "10px", borderBottom: "1px solid var(--border)" }}>
        <Input
          placeholder="Search threads..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)} // Update search input state
          style={{
            borderRadius: "8px",
            border: "1px solid var(--input-border)",
            backgroundColor: "var(--input-bg)",
            color: "var(--text-color)",
          }}
        />
      </div>

      {/* Thread List */}
      <List
        itemLayout="horizontal"
        dataSource={filteredThreads} // Use filtered threads
        renderItem={(thread) => {
          const { user, lastMessage } = thread;
          const isSelected = user._id === selectedUserId;

          return (
            <List.Item
              key={user._id}
              onClick={() => onSelectThread(user._id)}
              style={{
                background: isSelected ? "var(--primary-color)" : "var(--bg-color)",
                padding: "10px 15px",
                cursor: "pointer",
                transition: "background 0.3s ease",
                borderLeft: isSelected ? "4px solid var(--text-color)" : "4px solid transparent",
              }}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: isSelected ? "white" : "var(--primary-color)",
                      color: isSelected ? "var(--primary-color)" : "white",
                    }}
                  >
                    {user.first_name?.[0] || "U"}
                  </Avatar>
                }
                title={
                  <Text strong style={{ color: isSelected ? "white" : "var(--text-color)" }}>
                    {user.first_name || "Unknown"} {user.last_name || ""}
                  </Text>
                }
                description={
                  <Text
                    type="secondary"
                    ellipsis
                    style={{
                      color: isSelected ? "white" : "var(--text-secondary)",
                    }}
                  >
                    {lastMessage || "No messages yet"}
                  </Text>
                }
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default ThreadList;
