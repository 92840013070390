import { axiosJSON } from './AxiosInterceptor';

// Send a message
const sendMessage = async (messageData) => {
  try {
    const response = await axiosJSON.post('/api/chats/send', messageData);
    return response.data; // Should include { success, message, chat }
  } catch (error) {
    console.error('Error sending message:', error.response?.data || error.message);
    throw error; // Let the caller handle the error
  }
};

// Get chat history between two users
const getChatHistory = async (user1, user2) => {
  try {
    const response = await axiosJSON.get('/api/chats/history', {
      params: { user1, user2 },
    });
    return response.data; // Should include { success, chatHistory }
  } catch (error) {
    console.error('Error fetching chat history:', error.response?.data || error.message);
    throw error; // Let the caller handle the error
  }
};

// Get all chats for a user
const getUserChats = async (userId) => {
  try {
    const response = await axiosJSON.get(`/api/chats/user/${userId}`);
    return response.data; // Should include { success, chats }
  } catch (error) {
    console.error('Error fetching user chats:', error.response?.data || error.message);
    throw error; // Let the caller handle the error
  }
};

// Get all threads for a user
const getUserThreads = async (userId) => {
  try {
    const response = await axiosJSON.get('/api/chats/threads', {
      params: { userId },
    });
    return response.data; // Should include { success, threads }
  } catch (error) {
    console.error('Error fetching user threads:', error.response?.data || error.message);
    throw error; // Let the caller handle the error
  }
};

// Encapsulated ChatService object
const ChatService = {
  sendMessage,
  getChatHistory,
  getUserChats,
  getUserThreads,
};

export default ChatService;
