import React, { createContext, useState, useEffect } from 'react';
import { theme } from 'antd';
import LocalStorageService from '../services/LocalStorage';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // Initialize appearance from localStorage or default to 'system'
  const [appearance, setAppearance] = useState(
    LocalStorageService.getItem('appearancePreference') || 'dark'
  );

  // Compute the actual theme dynamically
  const [derivedTheme, setDerivedTheme] = useState(() =>
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', derivedTheme);

    if (appearance === 'system') {
      const matchDark = window.matchMedia('(prefers-color-scheme: dark)');
      const handleSystemChange = (e) => {
        const newTheme = e.matches ? 'dark' : 'light';
        setDerivedTheme(newTheme); // Set derived theme based on system preference
      };

      // Initial check
      handleSystemChange(matchDark);
      matchDark.addEventListener('change', handleSystemChange);

      return () => {
        matchDark.removeEventListener('change', handleSystemChange);
      };
    } else {
      setDerivedTheme(appearance); // Sync with chosen 'light' or 'dark' if not 'system'
    }
  }, [appearance, derivedTheme]);

  const updateAppearance = (newAppearance) => {
    setAppearance(newAppearance);
    LocalStorageService.setItem('appearancePreference', newAppearance);
  };

  const currentTheme =
    derivedTheme === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm;

  return (
    <ThemeContext.Provider value={{ appearance, currentTheme, updateAppearance }}>
      {children}
    </ThemeContext.Provider>
  );
};
