import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import LocalStorageService from './LocalStorage';

const API_URL = process.env.REACT_APP_SERVER_API_URL || "http://localhost:5000";

// JSON instance
const axiosJSON = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json' },
});

// Multipart instance
const axiosMultipart = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'multipart/form-data' },
});

const attachToken = async (config) => {
    const token = LocalStorageService.getItem('token');
    if (!token) {
        console.warn("No token found.");
        return config;
    }

    let decodedData;
    try {
        decodedData = await jwtDecode(token);
    } catch (error) {
        console.error("Invalid token.");
        LocalStorageService.logout();
        return config;
    }

    const currentTime = Date.now() / 1000;
    if (decodedData.exp < currentTime) {
        console.warn("Token has expired.");
        LocalStorageService.logout();
        return config;
    }

    config.headers['Authorization'] = `Bearer ${token}`;

    // Check for sendRole flag
    if (config.sendRole !== false) {
        const { role } = decodedData.data || {};
        if (role) {
            config.headers['roles'] = role;
        } else {
            console.warn("No role found in token.");
        }
    }

    return config;
};


// Attach interceptors
axiosJSON.interceptors.request.use(attachToken, (error) => Promise.reject(error));
axiosMultipart.interceptors.request.use(attachToken, (error) => Promise.reject(error));

axiosJSON.interceptors.response.use((response) => response);
axiosMultipart.interceptors.response.use((response) => response);

export { axiosJSON, axiosMultipart };
