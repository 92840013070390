import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { Button, ConfigProvider, Form, Input, notification } from 'antd';
import LocalStorageService from '../../services/LocalStorage';

function ForgetPassword() {
  const [form] = Form.useForm(); // Ant Design form instance
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0); // Timer state
  const navigate = useNavigate();
  const token = LocalStorageService.getItem("token");

  useEffect(() => {
    if (token) {
      navigate('/');
      return;
    }
  }, [token, navigate]);

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setTimeout(() => setTimer(timer - 1), 1000);
    }
    return () => clearTimeout(countdown);
  }, [timer]);

  const handleSubmit = async (values) => {
    const { email } = values;
    try {
      await form.validateFields(['email']);
    } catch (validationError) {
      notification.warning({
        message: 'Invalid Email',
        description: 'Please enter a valid email.',
        duration: 3,
      });
      return;
    }

    try {
      setLoading(true);
      await AuthService.request_reset_password(email);
      notification.success({
        message: 'Sent!',
        description: `Reset password link is sent to your email! 🎉`,
        duration: 3,
      });
      setTimer(180); // Start the countdown after successful request
    } catch (err) {
      notification.error({
        message: 'Reset Password Request Failed',
        description: err || 'An error occurred while sending the request. Please try again.',
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            primaryColor: 'Black',
          },
          Form: {
            itemMarginBottom: "8px",
          },
        },
      }}
    >
      <div className="main-container">
        <h1>Reset Password</h1>
        <Form
          form={form}
          name="forgetPasswordForm"
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit} // Trigger on successful form submission
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email',
              },
            ]}
          >
            <Input type="email" placeholder="Enter your email" />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              block
              loading={loading}
              disabled={timer > 0} // Disable button during countdown
            >
              {timer > 0
                ? `Request Again in ${timer}s`
                : loading
                ? 'Requesting...'
                : 'Request Forget Password'}
            </Button>
          </Form.Item>
        </Form>
        <p className="footer-text">
          Remembered your password? <Link to="/login">Login here</Link>
        </p>
      </div>
    </ConfigProvider>
  );
}

export default ForgetPassword;
