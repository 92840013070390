import React, { useState, useEffect, useRef } from "react";
import { List, Input, Button, Spin, notification } from "antd";
import LocalStorageService from "../../services/LocalStorage";
import socket from "../../services/Socket"; // Ensure socket is a singleton
import ChatService from "../../services/ChatService";

const { TextArea } = Input;

const ChatBox = ({ selectedUserId }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [loading, setLoading] = useState(false);

    // Logged-in user info
    const userData = JSON.parse(LocalStorageService.getItem("user"));
    const currentUserId = userData?.data?._id;

    const chatContainerRef = useRef(null); // Ref for chat container

    // Scroll to the latest message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    // Call scrollToBottom after messages are loaded or updated
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // Fetch history whenever selectedUserId changes
    useEffect(() => {
        if (!selectedUserId || !currentUserId) return;

        setLoading(true);
        ChatService.getChatHistory(currentUserId, selectedUserId)
            .then((res) => {
                if (res.success) {
                    setMessages(res.chatHistory);
                } else {
                    notification.error({
                        message: "Error",
                        description: "Unable to fetch chat history.",
                    });
                }
            })
            .catch((err) => {
                console.error("Chat history error:", err);
                notification.error({
                    message: "Error",
                    description: "Failed to load chat history.",
                });
            })
            .finally(() => setLoading(false));
    }, [selectedUserId, currentUserId]);


    // Join socket room on mount
    useEffect(() => {
        if (currentUserId) {
            socket.emit("join", currentUserId);
        }

        const handleReceiveMessage = (message) => {
            if (
                (message.sender._id === currentUserId && message.receiver._id === selectedUserId) ||
                (message.sender._id === selectedUserId && message.receiver._id === currentUserId)
            ) {
                setMessages((prev) => [...prev, message]);
            }
        };

        socket.on("receiveMessage", handleReceiveMessage);

        return () => {
            socket.off("receiveMessage", handleReceiveMessage);
        };
    }, [selectedUserId, currentUserId]);

    // Send message via Socket.IO
    const handleSendMessage = () => {
        if (!newMessage.trim()) return;

        const messageData = {
            sender: currentUserId,
            receiver: selectedUserId,
            content: newMessage.trim(),
        };

        if (!messageData.sender || !messageData.receiver || !messageData.content) {
            notification.error({
                message: "Error",
                description: "Invalid message data.",
            });
            return;
        }

        socket.emit("sendMessage", messageData, (ack) => {
            if (ack.status === "ok") {
                if (ack.data && ack.data.sender && ack.data.content) {
                    setMessages((prev) => [...prev, ack.data]);
                } else {
                    console.warn("Invalid acknowledgment data:", ack);
                }
                setNewMessage("");
            } else {
                notification.error({
                    message: "Error",
                    description: "Failed to send message.",
                });
            }
        });

    };

    if (!selectedUserId) {
        return (
            <div style={{ padding: "20px", display: "flex", justifyContent: "center", marginTop: "20%" }}>
                <h3>Select a thread to start chatting</h3>
            </div>
        );
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                backgroundColor: "var(--main-container-bg)",
                padding: "8px",
                borderRadius: "12px",
            }}
        >
            {loading ? (
                <Spin tip="Loading messages..." style={{ margin: "auto" }} />
            ) : (
                <div
                    ref={chatContainerRef} // Attach ref to chat container
                    style={{
                        flex: 1,
                        overflowY: "auto",
                        padding: "20px",
                        backgroundColor: "var(--bg-color)",
                        borderRadius: "8px",
                        marginBottom: "16px",
                        border: "1px solid var(--border)",
                        boxShadow: "0 2px 4px var(--shadow)",
                    }}
                >
                    <List
                        dataSource={messages}
                        renderItem={(msg, index) => {
                            if (!msg || !msg.sender || !msg.content) return null;

                            const isCurrentUser = msg.sender._id === currentUserId;

                            // Current and previous message timestamps
                            const currentMessageTime = new Date(msg.timestamp);
                            const previousMessageTime = index > 0 ? new Date(messages[index - 1]?.timestamp) : null;

                            // Determine if a day divider is needed
                            const showDayDivider =
                                !previousMessageTime ||
                                currentMessageTime.toDateString() !== previousMessageTime.toDateString();

                            return (
                                <React.Fragment key={msg._id || index}>
                                    {showDayDivider && (
                                        <div
                                            style={{
                                                textAlign: "center",
                                                margin: "10px 0",
                                                color: "var(--divider-color)",
                                                fontSize: "0.9rem",
                                                fontStyle: "italic",
                                            }}
                                        >
                                            {currentMessageTime.toLocaleDateString([], {
                                                weekday: "long",
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            })}
                                        </div>
                                    )}
                                    <List.Item
                                        style={{
                                            justifyContent: isCurrentUser ? "flex-end" : "flex-start", // Keep messages aligned left or right
                                            marginBottom: "10px",
                                            border: "none", // Remove List.Item default border
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: isCurrentUser ? "flex-end" : "flex-start", // Messages align by user type
                                                padding: "12px 16px",
                                                borderRadius: "16px",
                                                backgroundColor: isCurrentUser
                                                    ? "var(--primary-color)"
                                                    : "var(--input-bg)",
                                                color: isCurrentUser ? "var(--bg-color)" : "var(--text-color)",
                                                maxWidth: "70%",
                                                boxShadow: "0 2px 4px var(--shadow)",
                                                lineHeight: "1.5",
                                                textAlign: "left", // Ensure text inside the bubble starts from the left
                                            }}
                                        >
                                            <div>{msg.content}</div>
                                            <span
                                                style={{
                                                    fontSize: "0.75rem", // Smaller font size for time
                                                    color: "rgba(128, 128, 128, 0.6)", // Faded gray color
                                                    marginTop: "6px",
                                                    alignSelf: "flex-end", // Align time to the bottom-right
                                                }}
                                            >
                                                {currentMessageTime.toLocaleTimeString([], {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })}
                                            </span>
                                        </div>
                                    </List.Item>

                                </React.Fragment>
                            );
                        }}
                        style={{
                            border: "none", // Remove border from List container
                        }}
                    />
                </div>
            )}

            <div
                style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    borderTop: "1px solid var(--border)",
                    paddingTop: "10px",
                }}
            >
                <TextArea
                    rows={2}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                            setNewMessage("");
                        }
                    }}
                    placeholder="Type a message..."
                    style={{
                        flex: 1,
                        backgroundColor: "var(--input-bg)",
                        border: "1px solid var(--input-border)",
                        color: "var(--text-color)",
                        borderRadius: "8px",
                        padding: "10px",
                    }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        handleSendMessage();
                        setNewMessage("");
                    }}
                    style={{
                        backgroundColor: "var(--primary-color)",
                        borderColor: "var(--primary-color)",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px var(--shadow)",
                        padding: "0 20px",
                        height: "40px",
                    }}
                >
                    Send
                </Button>
            </div>
        </div>
    );

};

export default ChatBox;