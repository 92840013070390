import { axiosJSON } from './AxiosInterceptor'; // Assuming AxiosInterceptor is properly configured for JSON requests

/**
 * Fetch all bookings
 * @returns {Promise<Object[]>} - List of all bookings
 */
const getBookings = async () => {
  try {
    const response = await axiosJSON.get('/api/bookings');
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch bookings';
  }
};

/**
 * Fetch a single booking by ID
 * @param {string} id - Booking ID
 * @returns {Promise<Object>} - Booking details
 */
const getBookingById = async (id) => {
  try {
    const response = await axiosJSON.get(`/api/bookings/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch booking';
  }
};

/**
 * Fetch bookings for the logged-in user
 * @returns {Promise<Object[]>} - List of user's bookings
 */
const getUserBookings = async () => {
  try {
    const response = await axiosJSON.get('/api/bookings/by-user');
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch user bookings';
  }
};

const getOrganizationBookingsByUser = async (organization_id, adventure_id) => {
  try {
    const response = await axiosJSON.get(`/api/bookings/by-user-adventures?organization_id=${organization_id}&adventure_id=${adventure_id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch user bookings';
  }
};

/**
 * Create a new booking
 * @param {Object} bookingData - Booking details
 * @returns {Promise<Object>} - Created booking
 */
const createBooking = async (bookingData) => {
  try {
    const response = await axiosJSON.post('/api/bookings', bookingData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to create booking';
  }
};

/**
 * Update an existing booking
 * @param {string} id - Booking ID
 * @param {Object} bookingData - Updated booking details
 * @returns {Promise<Object>} - Updated booking
 */
const updateBooking = async (id, bookingData) => {
  try {
    const response = await axiosJSON.put(`/api/bookings/${id}`, bookingData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to update booking';
  }
};

/**
 * Delete a booking
 * @param {string} id - Booking ID
 * @returns {Promise<Object>} - Deletion response
 */
const deleteBooking = async (id) => {
  try {
    const response = await axiosJSON.delete(`/api/bookings/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete booking';
  }
};

/**
 * Service object encapsulating all booking-related API calls
 */
const BookingsService = {
  getBookings,
  getBookingById,
  getUserBookings,
  createBooking,
  updateBooking,
  deleteBooking,
  getOrganizationBookingsByUser
};

export default BookingsService;
