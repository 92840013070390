import React, { useEffect, useState } from "react";
import axios from "axios";
import { Layout, notification } from "antd";
import ThreadList from "../../components/Chat/ThreadList";
import ChatBox from "../../components/Chat/ChatBox";
import LocalStorageService from "../../services/LocalStorage";
import socket from "../../services/Socket"; // Ensure socket is a singleton

const { Content } = Layout;

const ChatPage = () => {
  const [threads, setThreads] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);

  // Current logged-in user
  const userData = JSON.parse(LocalStorageService.getItem("user"));
  const currentUserId = userData?.data?._id;

  // Fetch threads initially
  useEffect(() => {
    if (!currentUserId) {
      notification.error({ message: "Error", description: "User not logged in!" });
      return;
    }

    axios
      .get(`${process.env.REACT_APP_SERVER_API_URL}/api/chats/threads?userId=${currentUserId}`)
      .then((res) => {
        if (res.data.success) {
          setThreads(res.data.threads);
        } else {
          notification.error({ message: "Error", description: "Unable to fetch threads." });
        }
      })
      .catch((err) => {
        console.error("Thread fetch error:", err);
        notification.error({ message: "Error", description: "Failed to load chat threads." });
      });
  }, [currentUserId]);

  // Listen for real-time updates
  useEffect(() => {
    if (!currentUserId) return;

    const handleReceiveMessage = (message) => {
      const { sender, receiver, content } = message;
      const otherUserId = sender._id === currentUserId ? receiver._id : sender._id;

      setThreads((prevThreads) => {
        const existingThread = prevThreads.find((thread) => thread.user._id === otherUserId);

        if (existingThread) {
          // Update the last message in the thread
          return prevThreads.map((thread) =>
            thread.user._id === otherUserId
              ? { ...thread, lastMessage: content }
              : thread
          );
        } else {
          // Add a new thread if it doesn't exist
          return [
            ...prevThreads,
            {
              user: sender._id === currentUserId ? receiver : sender,
              lastMessage: content,
            },
          ];
        }
      });
    };

    socket.on("receiveMessage", handleReceiveMessage);

    return () => {
      socket.off("receiveMessage", handleReceiveMessage);
    };
  }, [currentUserId]);

  const handleSelectThread = (userId) => {
    setSelectedUserId(userId);
  };

  return (
    <div className="container" style={{marginTop: "2.1%"}}>
      {/* <Header style={{  color: "white", textAlign: "center" }}>
        <h2 style={{ color: "white", margin: 0 }}>Inbox</h2>
      </Header> */}

      <Content
        style={{
          display: "flex",
          margin: "20px auto",
          width: "100%",
          height: "91vh",
          borderRadius: "8px",
          overflow: "hidden",
          backgroundColor: "var(--main-container-bg)",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        }}
      >
        {/* Thread list (sidebar) */}
        <div
          style={{
            width: "300px", // Fixed width for the ThreadList
            borderRight: "1px solid var(--border)",
            overflowY: "auto",
            backgroundColor: "var(--main-container-bg)",
          }}
        >
          <ThreadList
            threads={threads}
            onSelectThread={handleSelectThread}
            selectedUserId={selectedUserId}
          />
        </div>

        {/* Chat Box (main content) */}
        <div style={{ height:"93%", width:"-webkit-fill-available" }}>
          <ChatBox selectedUserId={selectedUserId} />
        </div>
      </Content>

    </div>
  );
};

export default ChatPage;
