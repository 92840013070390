// services/socket.js
import io from "socket.io-client";
const SOCKET_URL = process.env.REACT_APP_SERVER_SOCKET_URL || "http://localhost:6001"
// const socket = io(process.env.REACT_APP_SERVER_API_URL);
const socket = io(SOCKET_URL , {
    path: "/api/socket.io",
    transports: ["websocket"],
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 2000,
});
export default socket;
