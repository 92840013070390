import React, { createContext, useState } from "react";

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    searchKeyword: "",
      priceRange: "",
      duration: "",
      selectedCity: null,
      minPrice: null, // Reset minimum price
      maxPrice: null, // Reset maximum price
      start_date: null, // Reset start date
      end_date: null, // Reset end date
  });

  const updateFilters = (newFilters) => {
    setFilters((prev) => ({ ...prev, ...newFilters }));
  };

  return (
    <FilterContext.Provider value={{ filters, updateFilters }}>
      {children}
    </FilterContext.Provider>
  );
};
